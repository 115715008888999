module.exports = function (buttonClass) {
  function matchPassword($passwordOne, $passwordTwo) {
    if ($passwordOne.val() === '' || $passwordTwo.val() === '') {
      $('[data-field=confirmPassword] .js-error').html('')
      return
    }
    if ($passwordOne.val() === $passwordTwo.val()) {
      $('[data-field=confirmPassword] .js-error').html('')
      $(buttonClass).removeAttr('disabled')
    } else {
      $(buttonClass).attr('disabled', 'disabled')
      $('[data-field=confirmPassword] .js-error').html(
        '<span class="form-row-description form-row-error-text">Passwords do not match</span>'
      )
    }
  }

  $(buttonClass).attr('disabled', 'disabled')

  $('input[name=password]').on('keyup', function () {
    matchPassword($('input[name=password]'), $('input[name=confirmPassword]'))
  })

  $('input[name=confirmPassword]').on('keyup', function () {
    matchPassword($('input[name=password]'), $('input[name=confirmPassword]'))
  })
}
