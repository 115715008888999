const formErrorsDelegate = require('./lib/form-errors-delegate')()
const performLogin = require('./lib/perform-login')
const apiUrl = $('.js-config').data('api-url')

// Needs to fudge a context
formErrorsDelegate.$ = $

module.exports = twoFaClickHandler

function twoFaClickHandler(data) {
  formErrorsDelegate.clearErrors()

  var postData = {
    code: $('input[name=code]').val(),
    remember: $('input[name=rememberTwoFa]').is(':checked'),
    adminId: data.id,
    hash: data.twoFaHash,
    fingerprint: data.fingerprint,
  }

  $.ajax({
    type: 'POST',
    url: apiUrl + '/verify-two-fa',
    data: JSON.stringify(postData),
    success: function (data) {
      if (data.key) {
        performLogin(data)
      } else {
        $('.js-errors-summary').html(data)
      }
    },
    context: this,
    error: function (error) {
      var parseError = JSON.parse(error.responseText)
      if (parseError.errors) {
        formErrorsDelegate.showErrors(parseError.errors)
      } else {
        formErrorsDelegate.showErrors(parseError)
      }
    },
    dataType: 'json',
    contentType: 'application/json',
  })
  return false
}
