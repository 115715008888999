window.jade = require('jade/runtime')
const compileJade = require('browjadify-compile')
const join = require('path').join

const formErrorsDelegate = require('./lib/form-errors-delegate')()
const $email = $('input[name=emailAddress]')
const $password = $('input[name=password]')
const twoFaTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (message, twoFaKey, twoFaQrUrl) {
buf.push("<div class=\"panel-header\"><h2>Two Step Verification Code</h2>");
if ( message)
{
buf.push("<p>" + (jade.escape(null == (jade_interp = message) ? "" : jade_interp)) + "</p>");
}
buf.push("</div><div class=\"panel-content\">");
if ( twoFaKey)
{
buf.push("<h3>Setup Google Authenticator</h3><p>Install the Google Authenticator app on your Phone</p><ol><li>On your phone, open up the App Store</li><li>Search for \"Google Authenticator\"</li><li>Download and install the application</li></ol><p>Open and Configure Google Authenticator</p><ol><li>In Google Authenticator, setup a new account</li><li>Choose \"Scan barcode\"</li><li>Use your phones camera to scan this QR code</li></ol><div class=\"text-centering\"> <img" + (jade.attr("src", twoFaQrUrl, true, false)) + " class=\"p\"/></div><p>Can't scan the barcode above?</p><ol><li>In Google Authenticator, setup a new account</li><li>Choose \"Manual Entry\"</li><li>Follow the on screen instructions, entering the key below when prompted</li></ol><pre class=\"text-centering\"><span>" + (jade.escape(null == (jade_interp = twoFaKey) ? "" : jade_interp)) + "</span></pre><p>Please enter the six digit verification code generated by Google Authenticator below</p>");
}
buf.push("<form method=\"post\"><div class=\"js-errors-summary\"></div><div data-field=\"code\" class=\"form-row\"><label><span class=\"form-label-text\">Code</span><input type=\"text\" name=\"code\" class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div><div data-field=\"rememberTwoFa\" class=\"form-row\"><label><input type=\"checkbox\" name=\"rememberTwoFa\" checked=\"checked\" class=\"control control--boolean\"/><span>Remember this device for 30 days?</span></label></div><div class=\"form-row form-row-actions\"><div class=\"form-field\"><button type=\"submit\" class=\"btn btn--action js-2fa-submit\">Submit</button></div></div></form></div>");}.call(this,"message" in locals_for_with?locals_for_with.message:typeof message!=="undefined"?message:undefined,"twoFaKey" in locals_for_with?locals_for_with.twoFaKey:typeof twoFaKey!=="undefined"?twoFaKey:undefined,"twoFaQrUrl" in locals_for_with?locals_for_with.twoFaQrUrl:typeof twoFaQrUrl!=="undefined"?twoFaQrUrl:undefined));;return buf.join("");
}
const twoFaClickHandler = require('./two-fa')
const performLogin = require('./lib/perform-login')
const FingerprintGenerator = require('fingerprintjs2')
const fingerprintGenerator = new FingerprintGenerator()
const qs = require('querystring')
const apiUrl = $('.js-config').data('api-url')
const adminUrl = $('.js-config').data('admin-url')

// Needs to fudge a context
formErrorsDelegate.$ = $

$('.js-forgot').on('click', function (e) {
  e.preventDefault()
  document.location =
    '/password-reset-request?emailAddress=' + encodeURIComponent($email.val())
})

function logout() {
  window.localStorage.removeItem('aclRoles')
  window.localStorage.removeItem('apiId')
  window.localStorage.removeItem('apiKey')
  window.localStorage.removeItem('apiTimeout')
  window.localStorage.removeItem('firstName')
}

// Make sure you aren't logged in
logout()

$('form').on('submit', function (e) {
  e.preventDefault()
  $('.js-login').trigger('click')
})

$('.js-login').on('click', function (e) {
  e.preventDefault()
  formErrorsDelegate.clearErrors()

  fingerprintGenerator.get(function (fingerprint) {
    const creds = {
      identity: $email.val(),
      password: $password.val(),
      fingerprint: fingerprint,
    }
    let qsData = null

    $.ajax({
      type: 'POST',
      url: apiUrl + '/auth',
      data: JSON.stringify(creds),
      success: function (data) {
        if (data.key) {
          // allow login
          performLogin(data)
        } else if (data.id && !data.forcePasswordReset) {
          // setup 2fa
          $('.js-panel-content').empty()
          if (data.twoFaKey) {
            // split string into 4 character chunks
            data.twoFaKey = data.twoFaKey
              .replace(/[^\dA-Z]/g, '')
              .replace(/(.{4})/g, '$1 ')
              .trim()
          }
          var twoFaContent = twoFaTemplate(data)
          $('.js-panel-content').append(twoFaContent)
          $('.js-2fa-submit').click(function (e) {
            e.preventDefault()
            data.fingerprint = fingerprint
            twoFaClickHandler(data)
          })
        } else if (data.forcePasswordReset) {
          // force password reset
          qsData = {
            emailAddress: data.emailAddress,
            token: data.resetToken.token,
            expiry: data.resetToken.expiry,
          }
          window.location.replace('/password-reset?' + qs.stringify(qsData))
        } else {
          // we had errors
          $('.js-errors-summary').html(data)
        }
      },
      context: this,
      error: function (error) {
        var parseError = JSON.parse(error.responseText)
        if (parseError.errors) {
          formErrorsDelegate.showErrors(parseError.errors)
        } else {
          formErrorsDelegate.showErrors(parseError)
        }
      },
      dataType: 'json',
      contentType: 'application/json',
    })
  })

  return false
})

$('.js-request').on('click', function (e) {
  e.preventDefault()
  var $email = $('input[name=emailAddress]')
  formErrorsDelegate.clearErrors()
  $.ajax({
    type: 'POST',
    url: apiUrl + '/administrator/password-reset-request',
    data: JSON.stringify({
      emailAddress: $email.val(),
      returnUrl: adminUrl + '/password-reset',
    }),
    success: function () {
      $('.panel-content').html('<p>Instructions have been sent to you.</p>')
    },
    context: this,
    error: function (error) {
      var parseError = JSON.parse(error.responseText)
      if (parseError.errors) {
        formErrorsDelegate.showErrors(parseError.errors)
      } else {
        formErrorsDelegate.showErrors(parseError)
      }
    },
    dataType: 'json',
    contentType: 'application/json',
  })
  return false
})

require('./lib/password-match')('.js-reset')

$('.js-reset').on('click', function (e) {
  e.preventDefault()
  var $password = $('input[name=password]')
  formErrorsDelegate.clearErrors()
  $.ajax({
    type: 'POST',
    url: apiUrl + '/administrator/password-reset',
    data: JSON.stringify({
      emailAddress: $('input[name=emailAddress]').val(),
      token: $('input[name=token]').val(),
      expiry: $('input[name=expiry]').val(),
      password: $password.val(),
    }),

    success: function () {
      document.location = '/login?reason=reset'
    },
    context: this,
    error: function (error) {
      var parseError = JSON.parse(error.responseText)
      if (parseError.errors) {
        formErrorsDelegate.showErrors(parseError.errors)
      } else {
        formErrorsDelegate.showErrors(parseError)
      }
    },
    dataType: 'json',
    contentType: 'application/json',
  })
  return false
})
